import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import { spacing } from '@nubank/nuds-web/styles/themeUtils';

import useInView from '@nubank/www-latam-commons/utils/hooks/useInView';
import { backgroundImage } from '@nubank/www-latam-commons/components/Image/Image';

const handleBackgroundImage = ({ backgroundImageUrl }) => {
  if (backgroundImageUrl) {
    const backgroundImagesUrls = backgroundImage(backgroundImageUrl);
    return css`
      background-size: cover;
      background-position: center;
      ${backgroundImagesUrls};
    `;
  }
  return undefined;
};

const handleMinHeight = ({ minHeight, contained, theme }) => {
  const minHeightContainedCss = minHeight === 'auto'
    ? css`min-height: auto;`
    : css`
      min-height: calc(${minHeight} - 32px);
      ${breakpointsMedia({ lg: css`min-height: calc(${minHeight} - 128px);` })};
    `;

  const containedOrDefaultCss = breakpointsMedia({
    lg: css`
      min-height: calc(100vh - ${contained ? 128 : theme.menuHeight.desktopLarge}px);
    `,
  });

  const onlyMinHeightCss = css`
    min-height: ${minHeight};
  `;

  if (typeof minHeight === 'object') {
    return breakpointsMedia(
      Object.fromEntries(Object.entries(minHeight)
        .map(([key, value]) => [key, `min-height: calc(${value} - ${theme.menuHeight.desktopLarge}px);`])),
    );
  }

  if (!minHeight) {
    return containedOrDefaultCss;
  }

  if (contained) {
    return minHeightContainedCss;
  }

  return onlyMinHeightCss;
};

const StyledSection = styled(Box)`
  &[data-visible="true"] {
    ${handleBackgroundImage}
  }
`;

const handleContained = ({ contained }) => contained && css`
  margin: ${spacing('4x')};
  box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  ${breakpointsMedia({ md: css`margin: ${spacing('16x')};` })};
`;

const StyledArticle = styled(Box)`
  max-width: ${({ theme }) => theme.pageMaxWidth};
  ${handleMinHeight};
  ${handleContained};
`;

function Section({
  children,
  backgroundColor,
  id,
  className,
  backgroundImageUrl,
  minHeight,
  contained,
}) {
  const [inViewRef, isVisible] = useInView();

  return (
    <StyledSection
      tag="section"
      id={id}
      ref={inViewRef}
      className={className}
      display="flex"
      justifyContent="center"
      padding="0"
      position="relative"
      data-visible={isVisible}
      backgroundColor={backgroundColor}
      backgroundImageUrl={backgroundImageUrl}
    >
      <StyledArticle
        tag="article"
        display="flex"
        width="100%"
        height={{ lg: '100%' }}
        flexWrap={{ md: 'wrap' }}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        minHeight={minHeight}
        contained={contained}
      >
        {children}
      </StyledArticle>
    </StyledSection>
  );
}

Section.defaultProps = {
  backgroundColor: 'white',
  backgroundImageUrl: undefined,
  id: undefined,
  minHeight: undefined,
  className: 'structure-section',
  contained: false,
};

Section.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImageUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contained: PropTypes.bool,
  id: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default React.memo(Section);
